import React, { useEffect, useState } from "react";
import { images } from "../../constants";
import { motion } from "framer-motion";
import "./Hero.css";
import { gtagSendEvent } from "../../utils/gtaghelper";

const HeroM = () => {
  const DEFAULT_PRICE = '10,999';
  
  // Define offer codes and their corresponding prices
  const OFFER_CODES = {
    'EARLY': 4499,
    'SPECIAL': 5999,
    'DEFAULT': '10,999',
    'NEWYEAR': 7999,
    'SINGLE': 2999,
    'CUSTOM': '15,999',
  };
  
  const [price, setPrice] = useState(DEFAULT_PRICE);
  const [offerApplied, setOfferApplied] = useState('');

  // Define feature lists for different offers
  const DEFAULT_FEATURES = [
    "4 to 10 Dynamic pages website",
    "SEO optimization",
    "Free hosting & free domain",
    "WhatsApp integration",
    "Contact form setup",
    "Google Ads Support*"
  ];
   const EARLY_FEATURES = [
    "1 to 4 Dynamic pages website",
    "SEO optimization",
    "Free hosting & free domain",
    "WhatsApp integration",
    "Contact form setup",
    "Google Ads Support*"
  ];

  const CUSTOM_FEATURES = [
    "9 to 20 dynamic pages website",
    "Advanced SEO optimization",
    "Free hosting & premium domain",
    "WhatsApp integration",
    "Advanced contact form setup",
    "Premium Google Ads Support*"
  ];

  const SINGLE_FEATURES = [
    "Single page website",
    "Basic SEO optimization",
    "Free hosting & free domain",
    "WhatsApp integration",
    "Contact form setup",
  ];

  const NEWYEAR_FEATURES = [
    "4 to 6 dynamic pages website",
    "Enhanced SEO optimization",
    "Free hosting & free domain",
    "WhatsApp integration",
    "Contact form setup",
    "Standard Google Ads Support*"
  ];

  const [features, setFeatures] = useState(DEFAULT_FEATURES);

  useEffect(() => {
    // Get URL parameters
    const params = new URLSearchParams(window.location.search);
    const offerCode = params.get('offer')?.toUpperCase();
    
    // Check if offer code exists and update price
    if (offerCode && OFFER_CODES.hasOwnProperty(offerCode)) {
      setPrice(OFFER_CODES[offerCode]);
      setOfferApplied(offerCode);
      
      // Update features list based on offer code
      switch(offerCode) {
        case 'CUSTOM':
          setFeatures(CUSTOM_FEATURES);
          break;
        case 'SINGLE':
          setFeatures(SINGLE_FEATURES);
          break;
        case 'NEWYEAR':
          setFeatures(NEWYEAR_FEATURES);
          break;
        case 'EARLY':
          setFeatures(EARLY_FEATURES);
          break;
        default:
          setFeatures(DEFAULT_FEATURES);
      }
    }
  }, []);

  return (
    <motion.div
      initial={{ y: -100, x: "0%", opacity: 0 }}
      animate={{ y: 0, x: "0%", opacity: 1 }}
      transition={{ delay: 0.2 }}
      className="hero"
    >
      <div className="row align-items-center pb-4">
        <div className="col-md-6 col-12">
          <h2 className="title">Best & Affordable Website Development in Bangalore</h2>
          <p className="py-1">
            <strong style={{ fontSize: "1.4rem" }}>
              <span style={{fontSize:"1.8rem"}}>4</span> {[...Array(4)].map((_, i) => (
                <svg
                  style={{ width: "20px", height: "20px" }}
                  key={i}
                  className="w-5 h-5"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"
                    style={{ fill: "#EAB308" }}
                  />
                </svg>
              ))} &nbsp;More than 200+ reviews on Google.
            </strong>
          </p>
          <p className="py-1">
            <strong style={{ fontSize: "1.4rem", lineHeight:"2rem"}}>
              Pricing starts at just{' '}
              {offerApplied ? (
                <>
                  <span style={{ textDecoration: 'line-through', color: '#666',opacity:"0.4" }}>₹{DEFAULT_PRICE}</span>
                  {' '}
                  <span className="pb-3">₹{price}*</span><br />
                  <span className="ms-2 p-1" style={{ color: "black", backgroundColor: "rgb(185, 255, 102)", fontSize: '1rem' }}>
                    {offerApplied} offer applied!
                  </span>
                </>
              ) : (
                `₹${price}*`
              )}
            </strong>
          </p>
          <ul className="features-list py-1">
            {features.map((feature, index) => (
              <li key={index}>
                <span style={{ color: "black", fontWeight: "bold" }}>
                  <svg style={{width:"20px",height:"20px"}} className="w-5 h-5 text-green-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                  </svg>
                </span> {feature}
              </li>
            ))}
          </ul>
          <a
            target="_blank"
            rel="noreferrer"
            href="tel:+91-6363053425"
            onClick={(e) => {
              e.preventDefault();
              gtagSendEvent("conversion_event_contact", "tel:+91-6363053425");
            }}
          >
            <button className="btn-positivus">Contact Us Now!</button>
          </a>
        </div>
        <div className="col-md-6 col-12 mt-md-0 mt-4">
          <img className="img-fluid" src={images.hero} alt="design" />
        </div>
      </div>
    </motion.div>
  );
};

export default HeroM;